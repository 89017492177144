  <template>
  <v-layout row class="align-center layout px-4 pt-4 app--page-header">
    <div class="page-header-left">
      <h2 class="pr-3">{{title}}</h2>
    </div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <Searchbar v-if="isdalamstore"/>
    <v-spacer></v-spacer>
    <div class="page-header-right">
<!--      <BtnShoppingCart/>
      <v-btn @click="refresh" icon>
        <v-icon class="text&#45;&#45;secondary">mdi-refresh</v-icon>
      </v-btn>-->
    </div>
  </v-layout>
</template>

<script>
import menu from '@/api/menu'
import { mapActions, mapState } from 'vuex'
import Utils from '@/plugins/utils'
import Searchbar from '@/components/irsxstore/Searchbar'
export default {
  name: 'PageHeader',
  components: { Searchbar },
  data () {
    return {
      title: ''
    }
  },
  computed: {
    ...mapState('IRSXStore', ['cart']),
    isdalamstore: function (){
      return this.$route.path.includes("irsxstore")
    },
    totalamount: function(){
      let tot=0
      for (let i = 0; i < this.cart.length; i++) {
        tot +=this.cart[i].price
      }
      return tot;
    },
    breadcrumbs: function () {
      const breadcrumbs = []
      menu.forEach(item => {
        if (item.items) {
          const child = item.items.find(i => {
            return i.component === this.$route.name
          })
          if (child) {
            breadcrumbs.push(item.title)
            breadcrumbs.push(child.title)
            this.title = child.title
          }
        } else {
          if (item.name === this.$route.name) {
            this.title = item.title
            breadcrumbs.push(item.title)
          }
        }
      })
      return breadcrumbs
    }
  },
  methods: {
    ...mapActions('IRSXStore', ['getCategories','getProducts','getOrders','clearchart']),
    formatAngka (val) {
      return Utils.formatAngka(val)
    },
    refresh() {
      if(this.$route.path.includes("irsxstore")){
        this.getProducts()
        this.getOrders()
      }
    }
  }
}
</script>
