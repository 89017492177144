<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)" elevation="0">
    <v-dialog scrollable v-model="dialog" persistent width="500">
      <v-card>
        <v-toolbar color="primary" dark flat dense cad>
          <v-toolbar-title class="subheading">{{titledialog}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="setdialog(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-xs-left">
          <v-form ref="form" lazy-validation>
            <div class="mt-4"/>
            <v-text-field label="BANK" v-model="newdata.bank"></v-text-field>
            <v-text-field label="Nama" v-model="newdata.nama"></v-text-field>
            <v-text-field label="No Rekening" v-model="newdata.norek"></v-text-field>
            <IconInforekUpload label="Icon" :value="newdata.icon"  />
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text dark @click="setdialog(false)" color="red">Tutup</v-btn>
          <v-btn text dark @click="simpan()" :loading="loading" color="green">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :search="opsitable.search"
      :items="inforekening"
      class="elevation-0"
      item-key="id"
      :options.sync="opsitable"
      :server-items-length="totalinforekening"
      :loading="loading"
      @update:options="getInfoRekening(opsitable)">
      <template v-slot:top>
        <v-toolbar elevation="0">
          <v-btn small elevation="0" class="" color="primary" @click.native="getInfoRekening(opsitable)">
            <v-icon dark>mdi-refresh</v-icon>Refresh
          </v-btn>
          <v-btn small elevation="0" class="ml-2" color="primary" @click.native="openaddnew()">
            <v-icon dark>mdi-plus</v-icon>Tambah
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.icon="{ item }">
        <div>
          <v-img max-height="36" max-width="36" :src="item.icon"/>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-row>
          <v-btn x-small fab text @click.native="openedit(item)" class="primary">
            <v-icon small>mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn icon x-small fab text @click.native="deleteconfm(item)" dark class="red ml-2">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import IconInforekUpload from "@/components/IconInforekUpload";

export default {
  name: "InfoRekeningPanel",
  components: {IconInforekUpload},
  data: ()=>({
    opsitable: {
      search:'',
      brand:"",
      itemsPerPage: 10,
      page:1,
      sortBy: ["id"],
    },
    newdata:{
      bank:"",
      nama:"",
      norek:"",
      icon:''
    },
    titledialog: 'Edit',
    state: 0,
    headers: [
      {
        text: 'id',
        value: 'id'
      },
      {
        text: 'Bank',
        value: 'bank'
      },
      {
        text: 'NoRek',
        value: 'norek'
      },
      {
        text: 'Nama',
        value: 'nama'
      },
      {
        text: 'Icon',
        value: 'icon'
      },
      {
        text: 'Aksi',
        value: 'action'
      },
    ]
  }),
  mounted () {
    this.setIcon('');
  },
  computed: {
    ...mapState('InfoRekeningStore',['loading','dialog','icon','inforekening','totalinforekening'])
  },
  methods: {
    ...mapActions('InfoRekeningStore',['setdialog','setloading','getInfoRekening','editInfoRekening','deleteInfoRekening','addInfoRekening','setIcon']),
    deleteconfm(item){
      this.delete_id = item.id
      this.$confirm('Yakin akan hapus ? '+item.bank, { color: 'red',title:'Hapus',icon:'fa-danger' }).then(res => {
        if (res) {
          this.deleteInfoRekening(item)
        }
      })
    },
    simpan(){
      if(this.newdata.tampil){
        this.newdata.tampil=1
      }else{
        this.newdata.tampil=0;
      }
      if(this.icon!==''){
        this.newdata.icon=this.icon
      }
      if(this.state===0) {
        this.editInfoRekening(this.newdata);
      }else {
        this.addInfoRekening(this.newdata);
      }
    },
    openaddnew(){
      this.state=1;
      this.newdata={}
      this.titledialog="Tambah"
      this.setdialog(true)
    },
    openedit(data){
      this.state=0;
      this.newdata=data;
      this.titledialog="Edit"
      this.setdialog(true)
    },
  }
}
</script>

<style scoped>

</style>
