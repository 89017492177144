<template>
  <div class="mt-2">
    <v-layout row wrap>
      <v-flex lg3  sm6 xs12>
        <v-card class="ma-3" elevation="0" style="border: thin solid rgba(0,0,0,.12)">
          <v-list-item>
            <v-sheet class="mt-n12" color="green" width="50" height="50" elevation="1">
              <v-icon dark large class="icondash">mdi-shopping-outline</v-icon>
            </v-sheet>
            <v-list-item-content>
              <div class="text-right mb-2">Total Transaksi</div>
              <v-list-item-title class="headline mb-1 text-right">{{formatAngka(dashboardata.totaltrx)}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex lg3  sm6 xs12>
        <v-card class="ma-3" elevation="0" style="border: thin solid rgba(0,0,0,.12)">
          <v-list-item>
            <v-sheet class="mt-n12" color="green" width="50" height="50" elevation="1">
              <v-icon dark large class="icondash">mdi-account-cash</v-icon>
            </v-sheet>
            <v-list-item-content>
              <div class="text-right mb-2">Omset</div>
              <v-list-item-title class="headline mb-1 text-right">{{formatAngka(dashboardata.omset)}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex lg3  sm6 xs12>
        <v-card class="ma-3" elevation="0" style="border: thin solid rgba(0,0,0,.12)">
          <v-list-item>
            <v-sheet class="mt-n12" color="green" width="50" height="50" elevation="1">
              <v-icon dark large class="icondash">mdi-account-box</v-icon>
            </v-sheet>
            <v-list-item-content>
              <div class="text-right mb-2">User</div>
              <v-list-item-title class="headline mb-1 text-right">{{formatAngka(dashboardata.totaluser)}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-flex>

      <v-flex lg3  sm6 xs12>
        <v-card class="ma-3" elevation="0" style="border: thin solid rgba(0,0,0,.12)">
          <v-list-item>
            <v-sheet class="mt-n12" color="green" width="50" height="50" elevation="1">
              <v-icon dark large class="icondash">mdi-wallet</v-icon>
            </v-sheet>
            <v-list-item-content>
              <div class="text-right mb-2">Wallet</div>
              <v-list-item-title class="headline mb-1 text-right">{{formatAngka(dashboardata.wallet)}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-flex>
    <!--            <v-flex lg6  sm6 xs12>-->
      <!--                <ChartPayment/>-->
      <!--            </v-flex>-->
      <!--            <v-flex lg6  sm6 xs12>-->
      <!--                <ChartProduct/>-->
      <!--            </v-flex>-->
<!--      <v-flex lg6  sm6 xs12 class="">
        <iframe frameborder=0  class="ml-2 mr-3" width="100%" height="315" src="https://www.youtube.com/embed/xvSk3qgrY9E?si=xY0hmHvMViKxZqPS">
        </iframe>
      </v-flex>
      <v-flex lg6  sm6 xs12 class="">
        <iframe frameborder="0"  class="ml-3" width="100%" height="315" src="https://www.youtube.com/embed/spVzLLYRHdQ?si=xnKaTaFELVJWbpT5" title="YouTube video player">
        </iframe>
      </v-flex>-->
    </v-layout>
  </div>
</template>

<script>
import Utils from '@/plugins/utils'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Dashboard',
  data: () => ({

  }),
  computed: {
    ...mapState('Dashboard',['dialog','sumreg','sumtrx','dashboardata'])
  },
  methods: {
    ...mapActions('Dashboard',['setdialog','getdashboarddata','getSumreguser','getSumTrx']),
    formatAngka(val){
      return Utils.formatAngka(val)
    },
  }
}
</script>

<style scoped>
.caro {
  height: 265px;
}
.icondash {
  margin-left: 6px;
  margin-top: 5px;
}
</style>

