import { render, staticRenderFns } from "./IconInforekUpload.vue?vue&type=template&id=349fd502&scoped=true&"
import script from "./IconInforekUpload.vue?vue&type=script&lang=js&"
export * from "./IconInforekUpload.vue?vue&type=script&lang=js&"
import style0 from "./IconInforekUpload.vue?vue&type=style&index=0&id=349fd502&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "349fd502",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VIcon,VProgressCircular})
