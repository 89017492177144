let apirl = ''
if (process.env.NODE_ENV === 'production') {
  apirl = location.origin;
} else {
  apirl = 'http://localhost:9999'
 // apirl = 'https://demoapp.aviana.id'
}

export const apiurl = apirl
export const version = '2.1.4'
