import Vue from 'vue'
import Vuex from 'vuex'
import { alert } from './alert.module'
import { authentication } from '@/store/auth.store'
import { Dashboard } from '@/store/dashboard.store'
import {  Banner } from '@/store/banner.store'
import { NewsStore } from '@/store/news.store'
import { MenuStore } from '@/store/menu.store'
import { RunningTextStore } from '@/store/runningtext.store'
import {UserStore} from '@/store/user.store'
import { PopupStore } from '@/store/popup.store'
import { SettingStore } from '@/store/setting.store'
import { PaymentGatewayStore } from '@/store/paymentgateway.store'
import { KycStore } from '@/store/kyc.store'
import { PushStore } from '@/store/push.store'
import { ProductStore } from '@/store/product.store'
import { StrukStore } from '@/store/struk.store'
import { IRSXStore } from '@/store/irsstore.store'
import { AplikasiStore } from '@/store/aplikasi.store'
import {IntroStore} from "@/store/intro.store";
import {sidompulStore} from "@/store/sidompul.store";
import {RitaStore} from "@/store/rita.store";
import {InfoRekeningStore} from "@/store/InfoRekeningStore";
import {ScreenLayoutStore} from "@/store/screenlayout.store";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    alert,
    authentication,
    Dashboard,
    Banner,
    NewsStore,
    MenuStore,
    RunningTextStore,
    UserStore,
    PopupStore,
    SettingStore,
    PaymentGatewayStore,
    KycStore,
    PushStore,
    ProductStore,
    StrukStore,
    IRSXStore,
    AplikasiStore,
    IntroStore,
    sidompulStore,
    RitaStore,
    InfoRekeningStore,
    ScreenLayoutStore
  }
})
