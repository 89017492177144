import { handleResponse } from './handleresponse'
import { apiurl } from '@/services/config'
const API_URL = apiurl
export const InfoRekeningService = {
  getInfoRekening,
   addInfoRekening,
  updateInfoRekening,
  deleteInfoRekening
}
function getInfoRekening () {
  return fetch(API_URL + '/api/admin/v1/inforekening', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}


function addInfoRekening (postdata) {
  if(postdata.status){
    postdata.status=1
  }else{
    postdata.status=0
  }
  return fetch(API_URL + '/api/admin/v1/inforekening', {
    method: 'POST',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function updateInfoRekening (postdata) {
  if(postdata.status){
    postdata.status=1
  }else{
    postdata.status=0
  }
  return fetch(API_URL + '/api/admin/v1/inforekening', {
    method: 'PATCH',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function deleteInfoRekening (postdata) {
  if(postdata.status){
    postdata.status=1
  }else{
    postdata.status=0
  }
  return fetch(API_URL + '/api/admin/v1/inforekening', {
    method: 'DELETE',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
