<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)" elevation="0">
    <v-dialog scrollable v-model="dialog" persistent width="500">
      <v-card>
        <v-toolbar color="primary" dark flat dense cad>
          <v-toolbar-title class="subheading">{{titledialog}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="setdialog(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-xs-left">
          <v-form ref="form" lazy-validation>
            <v-container class="mt-4">
              <v-layout fluid row wrap>
                <v-flex lg6 sm12 xs12 pa-20 class="pl-2">
                  <v-select
                    filled
                    dense
                    label="Group Menu"
                    item-text="nama"
                    item-value="id"
                    v-model="newdata.groupid"
                    :items="menugroup"
                  ></v-select>
                </v-flex>
                <v-flex lg6 sm12 xs12 pa-20 class="pl-2">
                  <v-select
                    filled
                    dense
                    label="Jenis Menu"
                    item-text="nama"
                    item-value="id"
                    v-model="newdata.tipe"
                    :items="jenisMenu"
                  ></v-select>
                </v-flex>
                <v-flex lg6 sm12 xs12 pa-20 class="pl-2">
                  <v-text-field label="Label" required v-model="newdata.label"></v-text-field>
                </v-flex>
                <v-flex lg6 sm12 xs12 pa-20 class="pl-2">
                  <v-text-field type="number" label="No Urut" v-model="newdata.posisi"></v-text-field>
                </v-flex>
                <v-flex v-if="cekDiableMenuItem(newdata)" lg12 sm12 xs12 pa-20 class="pl-2">
                  <v-autocomplete
                    :disabled="cekDiableOperator(newdata)"
                  v-model="operatorlist"
                  label="Operator"
                  :items="operator"
                  item-text="namaoperator"
                  item-value="idoperator"
                  chips
                  multiple>
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item)"
                      >
                        <v-avatar left>
                          <v-img :src="data.item.imgurl"></v-img>
                        </v-avatar>
                        {{ data.item.namaoperator }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex v-if="!cekDiableMenuItem(newdata)" lg12 sm12 xs12 pa-20 class="pl-2">
                  <v-autocomplete
                    v-model="submenulist"
                    label="Menu Items"
                    :items="menulookup"
                    item-text="label"
                    item-value="id"
                    chips
                    multiple>
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeSubMenu(data.item)"
                      >
                        <v-avatar left>
                          <v-img :src="data.item.icon"></v-img>
                        </v-avatar>
                        {{ data.item.label }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex lg6 sm12 xs12 pa-20 class="pl-2">
                  <v-text-field :disabled="cekDiableKodeproduk(newdata)" label="Kode Produk" v-model="newdata.idproduk"></v-text-field>
                </v-flex>
                <v-flex v-if="newdata.tipe===7" lg6 sm12 xs12 pa-20 class="pl-2">
                  <v-text-field  label="Url" placeholder="htt://example.com" v-model="newdata.url"></v-text-field>
                </v-flex>
                <v-flex v-if="cekShowMinMaxHarga(newdata)" lg6 sm12 xs12 pa-20 class="pl-2">
                  <v-text-field type="number" label="Min Harga" v-model="newdata.minprice"></v-text-field>
                </v-flex>
                <v-flex v-if="cekShowMinMaxHarga(newdata)" lg6 sm12 xs12 pa-20 class="pl-2">
                  <v-text-field type="number" label="Max Harga" v-model="newdata.maxprice"></v-text-field>
                </v-flex>
                <v-flex v-if="cekShowFormatTujuan(newdata)" lg6 sm12 xs12 pa-20 class="pl-2">
                  <v-text-field label="Format Tujuan" v-model="newdata.formattujuan"></v-text-field>
                </v-flex>
                <v-flex lg6 sm12 xs12 pa-20 class="pl-2">
                  <v-text-field label="Label Tujuan" v-model="newdata.labeltujuan"></v-text-field>
                </v-flex>
                <v-flex lg12 sm12 xs12 pa-20 class="pl-2">
                  <v-select
                    filled
                    dense
                    label="Keyboard Type"
                    item-text="text"
                    item-value="value"
                    v-model="newdata.keyboardtype"
                    :items="keyboardtypes"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
            <v-col cols="12" md="12 ">
              <IconUpload label="Icon" :value="icon"  />
              <v-row align-content="center" align="center" class="ml-2">
                <v-switch
                  flat icon
                  inset
                  v-model="newdata.home"
                  label="Home"/>
                <v-switch
                  flat icon class="ml-4"
                  inset
                  v-model="newdata.status"
                  label="Status"/>
              </v-row>
            </v-col>
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text dark @click="setdialog(false)" color="red">Tutup</v-btn>
          <v-btn text dark @click="simpan" :loading="loading" color="green">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :search="opsitable.search"
      :items="menu"
      class="elevation-0"
      item-key="id"
      :options.sync="opsitable"
      :server-items-length="totalmenu"
      :loading="loading"
      @update:options="getMenu(opsitable)">

      <template v-slot:top>
        <v-toolbar elevation="0">
          <v-text-field
            filled
            dense
            clearable
            rounded
            append-icon="mdi-text-search"
            placeholder="Type something"
            v-model="opsitable.search"
            hide-details
            class="hidden-sm-and-down"
          ></v-text-field>
          <v-divider
            class="mx-4 hidden-sm-and-down"
            inset
            vertical
          ></v-divider>
          <v-select filled dense rounded style="width: 50px;!important;" class="mt-4" single-line clearable item-text="nama"  v-model="opsitable.groupid" item-value="id" :items="menugroup"  label="Category"></v-select>
          <v-spacer></v-spacer>
          <v-btn small elevation="0" class="ml-2" color="primary" @click.native="openaddnew">
            <v-icon dark>mdi-plus</v-icon>Tambah
          </v-btn>
          <v-btn small elevation="0" class="ml-2" color="primary" @click.native="getMenu(opsitable)">
            <v-icon dark>mdi-refresh</v-icon>Refresh
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
      </template>

      <template v-slot:item.icon="{ item }">
        <div>
          <v-img max-height="36" max-width="36" :src="item.icon"/>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="ma-4 text-xs-left">
          <v-chip
            :color="colorStatus(item.status)"
            small
            text-color="white">
            {{ statusText(item.status) }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.tipe="{ item }">
        <div class="ma-4 text-xs-left">
          {{ typetotext(item.tipe) }}
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-row dense>

          <v-btn x-small  @click.native="openedit(item)" class="primary mr-1 mb-2">
            <v-icon small>mdi-square-edit-outline</v-icon>
            <v-spacer></v-spacer>
          </v-btn>
          <v-btn  x-small  @click.native="deleteconfm(item)" dark class="red">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import IconUpload from '@/components/IconUpload'

export default {
  name: 'MenuAppPanel',
  components: { IconUpload },
  data: ()=>({
    search: '',
    tab: '',
    newdata: {
      groupid:1,
      tipe:0,
      home:1,
      status:1,
      posisi:0,
      idoperator:'',
      idproduk:'',
      icon:'',
      minprice:0,
      maxprice:0,
      formattujuan:"",
      inqpattern:"",
      paypattern:"",
      labeltujuan:"",
      keyboardtype:0
    },
    opsitable: {
      search:'',
      itemsPerPage: 10,
      page:1,
      sortBy: ["id"],
    },
    opsiMenuGroup: {
      search:'',
      itemsPerPage: 10,
      page:1,
      sortBy: ["id"],
    },
    state: 0,
    operatorlist:[],
    submenulist:[],
    titledialog: "Tambah Menu",
    keyboardtypes:[
      {value:0, text:"Numeric"}, {value:1, text:"Text"}
    ],
    jenisMenux: [
      {id:1,nama:"Pembelian"},
      {id:2,nama:"Category"},
      {id:3,nama:"Category Filter Nomor"},
      {id:4,nama:"PPOB Single Produk"},
      {id:5,nama:"PPOB  Multi Produk"},
      {id:6,nama:"Voucher Fisik"},
      {id:7 ,nama:"WebSite"},
      {id:8 ,nama:"Digipos"},
      {id:10 ,nama:"Lainnya"},
      {id:13 ,nama:"TopUp Denom Bebas"},
      {id:14 ,nama:"Trf Bank Open"},
      {id:15 ,nama:"Tiket Pesawat"},
      {id:16 ,nama:"Tiket Kereta Api"},
      {id:17 ,nama:"Bulk Topup"},
      {id:18 ,nama:"Sidompul"},
      {id:19 ,nama:"ISimple"},
      {id:20 ,nama:"Topup + CekID"},
      {id:21 ,nama:"Submenu Popup"},
      {id:22 ,nama:"Submenu Screen"},
     // {id:11 ,nama:"ISimple"}
    ],
    headers: [
      {
        text: 'id',
        value: 'id'
      },
      {
        text: 'Icon',
        value: 'icon'
      },
      {
        text: 'Group',
        value: 'menugroup.nama'
      },
      {
        text: 'Label',
        value: 'label'
      },
      {
        text: 'tipe',
        value: 'menutipe.nama'
      },
      {
        text: 'IdOperator',
        value: 'idoperator'
      },
      {
        text: 'KodeProduk',
        value: 'idproduk'
      },
      {
        text: 'Url',
        value: 'url',
      },
      {
        text: 'Home',
        value: 'home'
      },
      {
        text: 'No Urut',
        value: 'posisi'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: 'Aksi',
        value: 'action'
      },
    ],
  }),
  mounted () {
    this.setIcon('');
    this.getJenisMenu(this.opsiMenuGroup)
    this.getMenuGroup(this.opsiMenuGroup)
    this.getMenuLookup(this.opsiMenuGroup)
    this.getOperator()
    this.getPriceplan()
  },
  computed: {
    ...mapState('MenuStore',['loading','dialog','menu','totalmenu','icon','menugroup','totalmenugroup','jenisMenu','menulookup']),
    ...mapState('ProductStore',['priceplan','operator'])
  },
  methods: {
    ...mapActions('MenuStore',['setSelectedMenu','setdialog','setIcon','getMenu','getJenisMenu','setloading','addMenu','editMenu','deleteMenu','getMenuGroup','getMenuLookup']),
    ...mapActions('ProductStore',['getOperator','getPriceplan']),
    simpan(){
      if(!this.newdata.label) return false

      if(this.icon!==''){
        this.newdata.icon=this.icon
      }
      if(this.newdata.home){
        this.newdata.home=1
      }else{
        this.newdata.home=0
      }
      if(this.newdata.status){
        this.newdata.status=1
      }else{
        this.newdata.status=0;
      }
      if(this.operatorlist.length>0){
        this.newdata.idoperator=this.operatorlist.join()
      }else{
        this.newdata.idoperator=''
      }
      if(this.submenulist.length>0){
        this.newdata.idoperator=this.submenulist.join()
      }else{
        if(this.operatorlist.length>0){
          this.newdata.idoperator=this.operatorlist.join()
        }else{
          this.newdata.idoperator=''
        }

      }

      if(this.state===1){
        this.addMenu(this.newdata)
      }else{
        this.editMenu(this.newdata)
      }
    },
    colorStatus(val){
      if(val===0){
        return "red";
      }
      if(val===1){
        return "green";
      }
    },
    statusText(val){
      if(val===0){
        return "Non-Aktif";
      }
      if(val===1){
        return "Aktif";
      }
    },
    openaddnew(){
      this.state=1;
      this.newdata={}
      this.newdata.groupid=1
      this.operatorlist=[]
      this.submenulist=[]
      this.setIcon('')
      this.titledialog="Tambah Menu"
      this.setdialog(true)
    },
    typetotext(tipe){
      switch (tipe) {
        case 1:
          return "Pembelian"
        case 2:
          return 'Category'
        case 3:
          return 'Category Filter Nomor'
        case 4:
          return 'PPOB Single Produk'
        case 5:
          return 'PPOB Multi'
        case 6:
          return 'Vocher Fisik'
        case 7:
          return 'WebSite'
        case 8:
          return 'Digipos'
        case 13:
          return 'Topup-DenomBebas'
        case 14:
          return 'Trf Bank-DenomBebas'
        default:
          return 'tidak dikenal'
      }
    },
    cekDiableMenuItem(data){
      return !(data.tipe===21 || data.tipe===22)
    },
    cekDiableOperator(data){
      return !(data.tipe === 2 || data.tipe === 17 || data.tipe === 30  || data.tipe === 20 || data.tipe === 3 || data.tipe === 5 || data.tipe === 0 || data.tipe === 1 || data.tipe === 13 );
    },
    cekDiableKodeproduk(data){
      return (data.tipe === 2 || data.tipe === 3 || data.tipe === 5 || data.tipe === 0);
    },
    cekShowFormatTujuan(data){
      return (data.tipe ===20 || data.tipe ===30);
    },
    cekShowMinMaxHarga(data){
      return (data.tipe ===13 || data.tipe === 30  || data.tipe === 14);
    },
    gotodetail(item){
      this.setSelectedMenu(item);
      this.$router.push('/menu/edit/'+item.id)
    },
    openedit(data){
      this.state=0;
      this.newdata=data;
      this.operatorlist=[]
      this.submenulist=[]
      if(typeof data.idoperator !='undefined' && data.idoperator.length>0){
        if(data.tipe===21 || data.tipe===22){
          if(this.newdata.idoperator.indexOf(",")>0){
            for (let i = 0; i < this.newdata.idoperator.split(",").length; i++) {
              this.submenulist.push(parseInt(this.newdata.idoperator.split(",")[i]))
            }
          }else{
            this.submenulist.push(parseInt(this.newdata.idoperator))
          }
        }else{
          if(this.newdata.idoperator.indexOf(",")>0){
            for (let i = 0; i < this.newdata.idoperator.split(",").length; i++) {
              this.operatorlist.push(parseInt(this.newdata.idoperator.split(",")[i]))
            }
          }else{
            this.operatorlist.push(parseInt(this.newdata.idoperator))
          }
        }

      }
      this.setIcon(data.icon)
      this.titledialog="Edit Menu"
      this.setdialog(true)
    },
    deleteconfm(item){
      this.delete_id = item.id
      this.$confirm('Yakin akan hapus ?', { color: 'red',title:'Hapus',icon:'fa-danger' }).then(res => {
        if (res) {
          this.deleteMenu(this.delete_id)
        }
      })
    },
    removeSubMenu(item){
      const index = this.submenulist.indexOf(item.id)
      if (index >= 0) this.submenulist.splice(index, 1)
    },
    remove (item) {
      const index = this.operatorlist.indexOf(item.idoperator)
      if (index >= 0) this.operatorlist.splice(index, 1)
    },
  }
}
//TODO
// image upload
</script>

<style scoped>

</style>
