const Menu = [
  {
    title: 'Dashboard',
    group: 'apps',
    icon: 'mdi-home',
    name: 'dashboard'
  },
  {
    title: 'Menu Applikasi',
    group: 'apps',
    icon: 'mdi-application-settings',
    name: 'menu'
  },
  {
    title: 'Intro',
    group: 'apps',
    icon: 'mdi-image-filter-black-white',
    name: 'intro'
  },
  {
    title: 'Banner',
    group: 'apps',
    icon: 'mdi-image-filter-black-white',
    name: 'banner'
  },
  {
    title: 'Berita',
    group: 'apps',
    icon: 'mdi-newspaper-variant-outline',
    name: 'news'
  },
  {
    title: 'Running Text',
    group: 'apps',
    icon: 'mdi-card-text-outline',
    name: 'runningtext'
  },
  {
    title: 'Popup Image',
    group: 'apps',
    icon: 'mdi-image-album',
    name: 'popupimage'
  },/*
  {
    title: 'XEN Payment GTW',
    group: 'apps',
    icon: 'mdi-cash-check',
    name: 'paymentgateway'
  },
  */
  {
    title: 'Module Operator',
    group: 'apps',
    icon: 'mdi-cash-check',
    name: 'moduleoperator'
  },
  {
    title: 'Screen Layout PPOB',
    group: 'apps',
    icon: 'mdi-cash-check',
    name: 'screenlayout'
  },
 // {
 //   title: 'KYC',
 //   group: 'apps',
 //   icon: 'mdi-account-check',
 //   name: 'kyc'
 // },
  {
    title: 'Settings',
    group: 'apps',
    icon: 'mdi-cog',
    name: 'setting'
  },
  {
    title: 'Struk Format',
    group: 'apps',
    icon: 'mdi-receipt',
    name: 'strukstting'
  },
/*  {
    title: 'IRSX Store',
    group: 'apps',
    icon: 'mdi-view-module',
    name: 'store'
  },*/
]
// reorder menu
Menu.forEach((item) => {
  if (item.items) {
    item.items.sort((x, y) => {
      const textA = x.title.toUpperCase()
      const textB = y.title.toUpperCase()
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
    })
  }
})

export default Menu
