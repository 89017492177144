import { MenuServices } from '@/services/menu.service'
import { MenuGroupServices } from '@/services/menugroup.service'

const state = {
  loading: false,
  dialoggroup:false,
  dialog: false,
  overlay: false,
  menu: [],
  menulookup:[],
  totalmenu: 0,
  icon:'',
  menugroup: [],
  jenisMenu:[
    {id:1,nama:"Pembelian"},
    {id:2,nama:"Category"}
  ],
  totalmenugroup: [],
  selectedmenu:{

  },
}
const actions = {
  setSelectedMenu ({ commit }, data) {
    commit('setselectedmenu', data)
  },
  setloading ({ commit }, data) {
    commit('setloading', data)
  },
  setIcon ({ commit }, image) {
    commit('seticon', image)
  },
  setdialoggroup ({ commit }, diag) {
    commit('setdialoggroup', diag)
  },
  setdialog ({ commit }, diag) {
    if(!diag){
      commit('seticon', '')
    }
    commit('setdialog', diag)
  },
  getMenu({dispatch, commit },opt) {
    commit('setloading',true)
    localStorage.setItem('filtermenu',JSON.stringify(opt))
    MenuServices.getAllMenu(opt).then(value => {
      commit('setdata',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })

  },
  getJenisMenu({dispatch, commit },opt) {
    commit('setloading',true)
    MenuServices.getAllJenisMenu(opt).then(value => {
      commit('setJenisMenu',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })

  },
  addMenu({ dispatch,commit },opt) {
    commit('setloading',true)
    console.log(opt)
    MenuServices.addMenu(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('getMenu',{
        search:'',
        itemsPerPage: 10,
        page:1,
        sortBy: ["id"],
      });
      dispatch('alert/success', value.msg, { root: true })
    }).catch(e=>{
      dispatch('alert/error', e, { root: true })
      commit('setloading',false)
    })
  },
  editMenu({ dispatch,commit },opt) {
    commit('setloading',true)
    MenuServices.editMenu(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      let filter = localStorage.getItem('filtermenu');
      dispatch('getMenu',JSON.parse(filter));
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  deleteMenu({ dispatch,commit },opt) {
    commit('setloading',true)
    console.log(opt)
    MenuServices.deleteMenu(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('getMenu',{
        search:'',
        itemsPerPage: 10,
        page:1,
        sortBy: ["id"],
      });
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  getMenuLookup({dispatch, commit },opt) {
    commit('setloading',true)
    opt.itemsPerPage=-1
    MenuServices.getAllMenu(opt).then(value => {
      commit('setdatamenulookup',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  getMenuGroup({dispatch, commit },opt) {
    commit('setloading',true)
    MenuGroupServices.getAllMenuGroup(opt).then(value => {
      commit('setdatagroup',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  addMenuGroup({ dispatch,commit },opt) {
    commit('setloading',true)
    console.log(opt)
    MenuGroupServices.addMenuGroup(opt).then(value => {
      commit('setloading',false)
      commit('setdialoggroup',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(e=>{
      dispatch('alert/error', e, { root: true })
      commit('setloading',false)
    })
  },
  editMenuGroup({ dispatch,commit },opt) {
    commit('setloading',true)
    MenuGroupServices.editMenuGroup(opt).then(value => {
      commit('setloading',false)
      commit('setdialoggroup',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  deleteMenuGroup({ dispatch,commit },opt) {
    commit('setloading',true)
    console.log(opt)
    MenuGroupServices.deleteMenuGroup(opt).then(value => {
      commit('setloading',false)
      commit('setdialoggroup',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  }
}
const mutations = {
  setselectedmenu (state, s){
    state.selectedmenu=s;
  },
  setloading (state, s) {
    state.loading = s
  },
  setdialog (state, s) {
    state.dialog = s
  },
  setdialoggroup (state, s) {
    state.dialoggroup = s
  },
  seticon (state, s) {
    state.icon = s
  },
  setdata (state, s) {
    state.menu = []
    state.menu = s.data.content
    state.totalmenu = s.data.totalElements
    state.loading = false
    state.overlay = false
  },
  setJenisMenu (state, s) {
    state.jenisMenu = []
    state.jenisMenu = s.data.content
    state.totalmenu = s.data.totalElements
    state.loading = false
    state.overlay = false
  },
  setdatagroup (state, s) {
    state.menugroup = []
    state.menugroup = s.data.content
    state.totalmenugroup = s.data.totalElements
    state.loading = false
    state.overlay = false
  },
  setdatamenulookup (state, s) {
    state.menulookup = []
    state.menulookup = s.data.content
    state.totalmenugroup = s.data.totalElements
    state.loading = false
    state.overlay = false
  }
}

export const MenuStore = {
  namespaced: true,
  state,
  actions,
  mutations
}
