<template>
  <div class="ma-2">
    <div class="mb-4">
      <h2>{{title}} Screen Layout</h2>
    </div>
    <v-divider></v-divider>
    <v-dialog width="500" v-model="rowediting">
      <v-card>
        <v-toolbar dense class="primary" dark>
          <v-toolbar-title>Edit Layout Item</v-toolbar-title>
          <v-spacer/>
          <v-btn icon @click="cancelLayoutEdit(false)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field class="mt-2" label="Label" v-model="selectedRowedit.label"></v-text-field>
          <v-textarea auto-grow rows="1" label="Value" v-model="selectedRowedit.value" />
          <v-text-field class="mt-2" label="Font" type="number" v-model="selectedRowedit.font"></v-text-field>
          <v-select
            v-model="selectedRowedit.position"
            :items="positions"
            item-text="label"
            item-value="value"
            dense
            outlined
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="cancelLayoutEdit(false)" text color="red">Tutup</v-btn>
          <v-btn @click="saveLayoutitem" text color="green">Simpan</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-layout row wrap class="mt-4">
      <v-flex lg8 md8  sm12 xs12>
        <v-row class="ml-0 pl-2 pr-2 border_all">
          <v-autocomplete
            label="Group Operator"
            placeholder="Group Operator"
            item-text="namaoperator"
            item-value="idoperator"
            :loading="loading"
            @change="operatorchange"
            v-model="selecteditem.idoperator"
            :items="operator">
          </v-autocomplete>
          <v-autocomplete
            label="Produk"
            placeholder="Produk"
            item-text="namaproduk"
            item-value="id"
            :loading="loading"
            @change="productchange"
            v-model="selecteditem.idproduk"
            :items="products">
          </v-autocomplete>
          <v-select
            label="Select"
            item-text="label"
            item-value="value"
            v-model="selecteditem.jenistrx"
            :items="jenistrx"
          ></v-select>
        </v-row>
        <v-row class="ml-0 pl-2 pr-2 border_all" lg8 md8  sm12 xs12>
          <v-textarea class="mt-1"  outlined label="Contoh Replay" v-model="selecteditem.contoh"></v-textarea>
        </v-row>
        <v-row class="ml-0 pl-2 pr-2 border_all" lg8 md8  sm12 xs12>
          <v-textarea label="Pattern" :error="!regexOK" outlined v-model="selecteditem.pattern"></v-textarea>
        </v-row>
        <v-row class="ml-0 pl-2 pr-2 border_all" lg8 md8  sm12 xs12>
          <div class="">
            <h4>Tag Tersedia</h4>
            <v-row dense class="pa-1 mt-2"  style="border: thin solid rgba(0,0,0,.12);border-radius: 4px;">
              <div  v-for="(item,index) in grouptext" :key="index">
                <v-chip pill @click="addtolayout(item)"  draggable class="pa-2 ma-1 primary">{{item.name}}={{item.value}}</v-chip>
              </div>
            </v-row>
            <v-row class="mr-1 ml-1 mt-4 mb-2">
              <v-flex class="d-flex justify-space-between">
                <h4>Tag dipakai</h4>
                <v-btn class="primary" @click="tambahBaris()">Tambah baris</v-btn>
              </v-flex>
            </v-row>
            <v-data-table
              disable-sort
              hide-default-footer
              :headers="headers"
              :items="selecteditem.items"
              class="elevation-0 mt-1"
              item-key="id"
              :server-items-length="itemtotal"
            >

              <template v-slot:body="props">
                <draggable v-model="myitems" tag="tbody">
                  <tr v-for="(item, index) in props.items" :key="index">
                    <td><v-icon small class="grab-icon">mdi-arrow-all</v-icon></td>
                    <td> {{ index + 1 }} </td>
                    <td>{{item.label}}</td>
                    <td>{{item.value}}</td>
                    <td>{{positionText(item.position)}}</td>
                    <td>{{item.font}}</td>
                    <td>
                      <div class="ga-1 d-flex justify-center">
                        <v-btn x-small text fab  @click.native="editLayoutitem(item)"  dark class="primary">
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn x-small text fab  @click.native="hapusLayoutitem(item)"  dark class="red">
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                </draggable>
              </template>

              <template v-slot:item.position="props">
                {{ positionText(props.item.position)}}
              </template>
              <template v-slot:item.label="props">
                <v-edit-dialog
                  :return-value.sync="props.item.label"
                  @save="saveEdit()"
                  @cancel="cancelEdit"
                  @open="openEdit"
                  @close="closeEdit"
                >
                  {{ props.item.label }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.label"
                      :rules="[max25chars]"
                      label="Edit"
                      single-line
                      counter
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.action="{ item }">
                <div class="ga-1 d-flex justify-center">
                  <v-btn x-small text fab  @click.native="editLayoutitem(item)"  dark class="primary">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn x-small text fab  @click.native="hapusLayoutitem(item)"  dark class="red">
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </div>
        </v-row>
        <v-flex lg8 md8  sm12 xs12 class="mt-1">
          <v-spacer></v-spacer>
          <v-btn @click.native="simpan" class="primary mt-4"><v-icon>mdi-content-save</v-icon> Simpan</v-btn>
        </v-flex>
      </v-flex>

      <v-flex lg4 md4  sm12 xs12 class="panelstruk">
        <v-card elevation="1" class="mr-4 ml-4">
          <div class="primary justify-center text-center white">
            <p class="white--text text--white pa-2">TAMPILAN LAYOUT</p>
          </div>
          <v-card-text>
            <v-list class="mt-0">
              <v-list-item  dense v-for="(item,index) in screenpreview" :key="index">
                <v-row v-if="item.type==='header'" justify="center" class="">
                  <div class="mt-0">{{item.label}}</div><div class="font-weight-bold text-center">{{item.value}}</div>
                </v-row>
                <v-row v-if="item.type==='content'" justify="space-between" class="">
                  <div class="mt-0">{{item.label}}</div><div class="font-weight-bold">{{item.value}}</div>
                </v-row>
                <v-row v-if="item.type==='footer'" justify="center" class="text-center">
                  <div class="mt-0">{{item.label}}</div><div class="font-weight-bold">{{item.value}}</div>
                </v-row>
              </v-list-item>
            </v-list>

            <div class="mt-10"></div>
            <v-btn block elevation="0" color="primary">Lanjut</v-btn>
            <div style="background-color: rgb(245, 245, 245); font-weight: bold">
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import moment from "moment";
import Utils from "@/plugins/utils";
import pad from "utils-pad-string";
import S from "string";
import draggable from 'vuedraggable'

export default {
  name: "MenuDetail",
  components: {draggable},
  data: ()=>({
    rowediting:false,
    snack: false,
    snackColor: '',
    snackText: '',
    selectedRowedit: {},
    regexOK:false,
    max25chars: v => v.length <= 25 || 'Input too long!',
    namedgroup: [],
    grouptext:[],
    title:"Edit",
    tipedata: [
      {
        label:"Header",
        value:"header"
      },
      {
        label:"Content",
        value:"content"
      },
      {
        label:"Footer",
        value:"footer"
      }
    ],
    positions:[
      {
        label:"Tengah",
        value:0
      },
      {
        label:"Kiri",
        value:1
      },
      {
        label:"Kanan",
        value:2
      },
      {
        label:"Justify",
        value: 3
      }
    ],
    jenistrx:[
      {
        label:"INQUIRY",
        value:5
      },
      {
        label:"PAYMENT",
        value:6
      }
    ],
    hasilstruk:'',
    screenpreview:[],
    headers:[
      {
        text: '',
        value: 'no1'
      },
      {
        text: 'No',
        value: 'no'
      },
      {
        text: 'Label',
        value: 'label'
      },
      {
        text: 'Nilai',
        value: 'value'
      },
      {
        text: 'Posisi',
        value: 'position'
      },
/*      {
        text: 'Lokasi',
        value: 'type'
      },*/
      {
        text: 'Font',
        value: 'font'
      },
      {
        text: 'Aksi',
        value: 'action',
        align: 'center'
      },
    ],
    state:0   // 0 new  1 update
  }),
  mounted () {
    this.getOperator();
    if (this.$route.params.id) {
      this.parseAll();
      this.state=1
      this.title="Edit"
      this.getScreenLayoutByid(this.$route.params.id)
      this.getProdukByOperator(this.selecteditem.idoperator)
      setTimeout(()=>{
        this.parseAll()
      },500)
    }else{
      // newdata
      this.title="Tambah"
      this.state=0
    }
  },
  watch: {
    sourcetext: function (){
      this.parseAll()
    },
    pat: function (){
      this.parseAll()
    },
    fstruk:function (){
      this.parseAll()
    }
  },
  computed: {
   // ...mapState('MenuStore',['selectedmenu','loading','dialog','menu','totalmenu','icon','menugroup','totalmenugroup','jenisMenu','menulookup']),
    ...mapState('ScreenLayoutStore',['loading','dialog','screenlayout','totalscreenlayout',"selecteditem",'itemtotal']),
    ...mapState('ProductStore',['priceplan','operator','products']),
    myitems: {
      get() {
        return this.selecteditem.items
      },
      set(value) {
        console.log(value)
        this.$store.commit('ScreenLayoutStore/setItems', value)
        this.parseAll()
        //this.$store.commit('updateList', value)
      }
    },
    pat: function(){
      return this.selecteditem.pattern
    },
    sourcetext: function(){
        return this.selecteditem.contoh;
    },
    fstruk: function(){
      return this.selecteditem.struk
    }
  },
  methods: {
    ...mapActions('ScreenLayoutStore',['setloading',"setdialog",'getScreenLayout','getScreenLayoutByid','newData','updateData','deleteData','setItemTotal']),
    ...mapActions('ProductStore',['getOperator','getPriceplan','getProdukByOperator']),
    productchange(){

    },
    tambahBaris () {
      this.selecteditem.items.push({"label":"","value":"STRUK PEMBAYARAN TAGIHAN LISTRIK","position":0,type:"header",font:0})
      this.parseAll()
    },
    changePosition (item){
      console.log(item)
      this.parseAll();
    },
    changecontenttype (item){
      console.log(item)
      this.parseAll();
    },
    operatorchange(){
      this.getProdukByOperator(this.selecteditem.idoperator)
    },
    simpan(){
      if(this.state===0){
        this.newData(this.selecteditem)
      }else{
        this.updateData(this.selecteditem)
      }
    },
    addtolayout(item){
      this.selecteditem.items.push({label: item.name.replace("<","").replace(">",""),value: item.name,position:3,type:"content",font:0})
      this.setItemTotal(this.selecteditem.items.length);
      this.parseAll()
    },
    hapusLayoutitem(item){
      // this.selecteditem.items.pop(item)
      this.selecteditem.items.splice(this.selecteditem.items.indexOf(item),1)
      this.parseAll()
    },
    cancelLayoutEdit () {
      this.rowediting=false
    },
    saveLayoutitem(item){
      console.log(item)
      this.rowediting=false
      this.parseAll()
    },
    editLayoutitem (item) {
      console.log(item)
      this.selectedRowedit=item
      this.rowediting=true
    },
    parseAll(){
      this.getNamedGroup()
      try {
        var regex = new RegExp(this.selecteditem.pattern, "g");
        var match= regex.exec(this.sourcetext)
      //  console.log(match);
       // this.hasilinq=this.formatlocal(this.selectedmenu.struk);
        this.parsematch2(match);
      }catch (e) {
        //console.log(e)
      }
    },
    parsematch2(match){
      this.screenpreview=[]
      if(match!=null){
        this.regexOK=true
      //  for (let x = 0; x < this.selecteditem.items.length; x++) {
          this.grouptext=[]
       // this.screenpreview.push({"label":"","value":"STRUK PEMBAYARAN TAGIHAN LISTRIK","position":0,type:"header"})
       // this.screenpreview.push({"label":"","value":"","position":0,type:"header"})

        for (let i = 0; i < this.namedgroup.length; i++) {
          this.grouptext.push({name:'<'+this.namedgroup[i]+'>',value:match.groups[this.namedgroup[i]].trim()})
        }
          for (let x = 0; x < this.selecteditem.items.length; x++) {
            if(this.selecteditem.items[x].label!==''){
              for (let i = 0; i < this.namedgroup.length; i++) {
                if(this.selecteditem.items[x].value==='<'+this.namedgroup[i]+'>'){
                  this.screenpreview.push({"label":this.selecteditem.items[x].label,value:this.selecteditem.items[x].value.replaceAll('<'+this.namedgroup[i]+'>',match.groups[this.namedgroup[i]].trim()),type:"content",position:1})
                }
              }
            }else{
              this.screenpreview.push({"label":this.selecteditem.items[x].label,value:this.selecteditem.items[x].value.replaceAll("<waktu>",moment().format('DD/MM/yyyy HH:mm:ss')),type:this.selecteditem.items[x].type,position:0})
            }
          }

          /*
          for (let i = 0; i < this.namedgroup.length; i++) {
            this.grouptext.push({name:'<'+this.namedgroup[i]+'>',value:match.groups[this.namedgroup[i]].trim()})
            for (let x = 0; x < this.selecteditem.items.length; x++) {
              if(this.selecteditem.items[x].value==='<'+this.namedgroup[i]+'>'){
                this.screenpreview.push({"label":this.selecteditem.items[x].label,value:this.selecteditem.items[x].value.replaceAll('<'+this.namedgroup[i]+'>',match.groups[this.namedgroup[i]].trim()),type:"content",position:1})
              }
            }
          }
          */


      }else{
        this.regexOK=false
      }
     // return a;
    },
    parsematch(match){
      let a=this.hasilstruk
      let jasaloket=2000
      let denom=100000
      let sn='02102020203123213'
      a=a.replace('<jasaloket>','Rp'+pad(this.formatAngka(jasaloket),10,{'lpad':'.'}))
      a=a.replace('<denom>','Rp'+pad(this.formatAngka(denom),10,{'lpad':'.'}))
      this.grouptext=[]
      if(match!=null){
        this.regexOK=true
        for (let i = 0; i < this.namedgroup.length; i++) {
          this.grouptext.push({name:'<'+this.namedgroup[i]+'>',value:match.groups[this.namedgroup[i]].trim()})
          if(S(this.namedgroup[i]).startsWith('rp') || S(this.namedgroup[i]).contains('totalbayar')||S(this.namedgroup[i]).contains('total') || S(this.namedgroup[i]).contains('admin') || S(this.namedgroup[i]).contains('tag')){
            a=a.replace('<'+this.namedgroup[i]+'>','Rp'+pad(this.formatAngka(match.groups[this.namedgroup[i]].trim()),10,{'lpad':'.'}))
          }else{
            a=a.replace('<'+this.namedgroup[i]+'>',match.groups[this.namedgroup[i]].trim())
          }
        }
      }else{
        this.regexOK=false
      }
      let x = denom+jasaloket;
      a=a.replace('<denom+jasaloket>','Rp'+pad(this.formatAngka(x),10,{'lpad':'.'}))
      a=a.replace('<sn>',sn)
      a=a.replace('<idrs>',"ID0001")
      a=a.replace("<namars>",'TEST NAMA')
      a=a.replace("<alamatrs>",'Jln Maju Makmur No 10,Denpasar BALI')
      a=a.replace("<phoners>",'081xxxx9999')
      return a;
    },
    formatlocal(input){
      let dt = moment().format('DD/MM/YYYY HH:mm:ss')
      return input.replace("<waktu>",dt)
    },
    formatAngka(val){
      return Utils.formatAngka(val)
    },
    formatDate (value) {
      return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
    },
    typetotext(tipe){
      switch (tipe) {
        case 1:
          return "Pembelian"
        case 2:
          return 'Category'
        case 3:
          return 'Category Filter Nomor'
        case 4:
          return 'PPOB Single Produk'
        case 5:
          return 'PPOB Multi'
        case 6:
          return 'Vocher Fisik'
        case 7:
          return 'WebSite'
        case 8:
          return 'Digipos'
        case 13:
          return 'Topup-DenomBebas'
        case 14:
          return 'Trf Bank-DenomBebas'
        default:
          return 'tidak dikenal'
      }
    },
    cekDiableMenuItem(data){
      return !(data.tipe===21 || data.tipe===22)
    },
    cekDiableOperator(data){
      return !(data.tipe === 2 || data.tipe === 20 || data.tipe === 3 || data.tipe === 5 || data.tipe === 0 || data.tipe === 1 || data.tipe === 13 || data.tipe === 14);
    },
    cekDiableKodeproduk(data){
      return (data.tipe === 2 || data.tipe === 3 || data.tipe === 5 || data.tipe === 0);
    },
    getNamedGroup(){
      this.namedgroup=[]
      var regex=/\(\?<([a-zA-Z][a-zA-Z0-9]*)>/g
      var match = regex.exec(this.selecteditem.pattern)
      while (match != null) {
        this.namedgroup.push(match[1])
        match = regex.exec(this.selecteditem.pattern);
      }
    },
    saveEdit (item) {
      this.parseAll()
      console.log(item);
      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'OK'
    },
    cancelEdit () {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },

    openEdit () {
     // this.snack = true
     // this.snackColor = 'info'
     // this.snackText = 'Dialog opened'
    },
    closeEdit () {
    },
    positionText (item) {
      switch (item) {
        case 0:
          return 'Tengah'
        case 1:
          return 'Kiri'
        case 2:
          return 'Kanan'
        case 3:
          return 'Rata Tengah'
        default:
          return 'Justify'
      }
    }
  }
}
</script>

<style scoped>
.ga-1 {
  gap: 4px;
}
.linecontrol {
  display: flex;
  align-items: center !important;
}
.panelstruk {
  position: fixed;
  top: 125px;
  right: 0;
  width: 400px;
}
.grab-icon {
   cursor: move;
}
</style>
