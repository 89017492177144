import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Login from '@/views/Login'
import ResetPassToken from '@/views/ResetPassToken'
import ActivateUser from '@/views/ActivateUser'
import ForgotPass from '@/views/ForgotPass'
import Banner from '@/views/Banner'
import News from '@/views/News'
import Users from '@/views/Users'
import Dashboard from '@/views/Dashboard'
import RunningText from '@/views/RunningText'
import GantiPassword from '@/views/GantiPassword'
import PopupImage from '@/views/PopupImage'
import Setting from '@/views/Setting'
import PaymentGateway from '@/views/PaymentGateway'
import Kyc from '@/views/Kyc'
import MenuApplikasi from '@/views/MenuApplikasi'
import UserDetail from '@/views/UserDetail'
import CetakStrukBuilder from '@/views/CetakStrukBuilder'
import StrukList from '@/views/StrukList'
import StorePage from '@/views/StorePage'
import DetailProductStore from '@/views/DetailProductStore'
import IntroPage from "@/views/IntroPage";
import ModuleOperatorPage from "@/views/ModuleOperatorPage";
import MenuDetail from "@/views/MenuDetail";
import ScreenLayoutPage from "@/views/ScreenLayoutPage";

Vue.use(VueRouter)

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      public: false
    },
    component: Dashboard
  },
  {
    path: '/menu',
    name: 'menu',
    meta: {
      public: false,
      breadcrumb: true
    },
    component: MenuApplikasi
  },
  {
    path: '/menu/edit/:id',
    name: 'menuedit',
    component: MenuDetail,
    meta: {
      public: false,
      breadcrumb: false
    }
  },
  {
    path: '/screenlayout/new',
    name: 'layouteditnew',
    component: MenuDetail,
    meta: {
      public: false,
      breadcrumb: false
    }
  },
  {
    path: '/screenlayout/edit/:id',
    name: 'layoutedit',
    component: MenuDetail,
    meta: {
      public: false,
      breadcrumb: false
    }
  },
  {
    path: '/banner',
    name: 'banner',
    meta: {
      public: false,
      breadcrumb: true
    },
    component: Banner
  },
  {
    path: '/intro',
    name: 'intro',
    meta: {
      public: false,
      breadcrumb: true
    },
    component: IntroPage
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      public: false,
      breadcrumb: true
    },
    component: News
  },
  {
    path: '/info',
    name: 'runningtext',
    meta: {
      public: false,
      breadcrumb: true
    },
    component: RunningText
  },
  {
    path: '/popupimage',
    name: 'popupimage',
    meta: {
      public: false,
      breadcrumb: true
    },
    component: PopupImage
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      public: false,
      breadcrumb: true
    },
    component: Users
  },
  {
    path: '/user/:id',
    name: 'userdetail',
    meta: {
      public: false,
      breadcrumb: true
    },
    component: UserDetail
  },
  {
    path: '/changepassword',
    name: 'changepassword',
    component: GantiPassword,
    meta: {
      public: false,
      breadcrumb: true
    }
  },
  {
    path: '/paymentgateway',
    name: 'paymentgateway',
    component: PaymentGateway,
    meta: {
      public: false,
      breadcrumb: true
    }
  },
  {
    path: '/moduleoperator',
    name: 'moduleoperator',
    component: ModuleOperatorPage,
    meta: {
      public: false,
      breadcrumb: true
    }
  },
  {
    path: '/screenlayout',
    name: 'screenlayout',
    component: ScreenLayoutPage,
    meta: {
      public: false,
      breadcrumb: true
    }
  },
  {
    path: '/kyc',
    name: 'kyc',
    component: Kyc,
    meta: {
      public: false,
      breadcrumb: true
    }
  },
  {
    path: '/setting',
    name: 'setting',
    component: Setting,
    meta: {
      public: false,
      breadcrumb: true
    }
  },
  {
    path: '/irsxstore',
    name: 'store',
    component: StorePage,
    meta: {
      public: false,
      breadcrumb: true
    }
  },
  {
    path: '/irsxstore/product/detail',
    name: 'storeproductdetail',
    component: DetailProductStore,
    meta: {
      public: false,
      breadcrumb: true
    }
  },
  {
    path: '/cetakstruk',
    name: 'strukstting',
    component: StrukList,
    meta: {
      public: false,
      breadcrumb: true
    }
  },
  {
    path: '/cetakstruk/new',
    name: 'cetakstruknew',
    component: CetakStrukBuilder,
    meta: {
      public: false,
      breadcrumb: false
    }
  },
  {
    path: '/cetakstruk/edit/:id',
    name: 'cetakstrukedit',
    component: CetakStrukBuilder,
    meta: {
      public: false,
      breadcrumb: false
    }
  },
  {
    path: '/login',
    name: 'LoginPage',
    meta: {
      public: true
    },
    component: Login
  },
  {
    path: '/forgot',
    name: 'forgot',
    meta: {
      public: true
    },
    component: ForgotPass
  },
  {
    path: '/resetpass/:token',
    meta: {
      public: true
    },
    component: ResetPassToken
  },
  {
    path: '/activate/:token',
    meta: {
      public: true
    },
    component: ActivateUser
  }
]

const router = new VueRouter({
  routes
})
// router gards
router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  NProgress.done()
})

export default router
