import {screenLayoutService} from "@/services/screenlayout.service";

const state = {
  loading: false,
  dialog: false,
  overlay: false,
  screenlayout: [],
  totalscreenlayout: 0,
  itemtotal:0,
  selecteditem:{
    idoperator:-1,
    idproduk:-1,
    jenistrx:5,
    pattern:"",
    contoh:"",
    items:[
      {
        label:"IDPELANGGAN",
        value:"<idpel>"
      },
      {
        label:"NAMA",
        value:"<nama>"
      },
      {
        label:"STAND METER",
        value:"<stanmeter>"
      },
      {
        label:"RP TAG",
        value:"<rptag>"
      },
      {
        label:"ADMIN",
        value:"<admin>"
      },
      {
        label:"TOTALBAYAR",
        value:"<totalbayar>"
      }
    ]
  }
}
const actions = {
  setItemTotal ({ commit },data) {
    commit('setItemTotal',data)
  },
  setselecteditem ({ commit },data) {
    commit('setselected',data)
  },
  resetselecteditem ({ commit }) {
    commit('resetselecteditem')
  },
  setloading ({ commit }, data) {
    commit('setloading', data)
  },
  setdialog ({ commit }, diag) {
    commit('setdialog', diag)
  },
  getScreenLayout({dispatch, commit },opt) {
    commit('setloading',true)
    screenLayoutService.getScreenLayout(opt).then(value => {
      commit('setdata',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  getScreenLayoutByid({dispatch, commit },opt) {
    commit('setloading',true)
    screenLayoutService.getScreenLayoutByid(opt).then(value => {
      commit('setdataselected',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  newData({dispatch, commit },opt) {
    commit('setloading',true)
    screenLayoutService.addScreenLayout(opt).then(value => {
      commit('setloading',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  updateData({dispatch, commit },opt) {
    commit('setloading',true)
    screenLayoutService.updateScreenLayout(opt).then(value => {
      commit('setloading',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  deleteData({dispatch, commit },opt) {
    commit('setloading',true)
    screenLayoutService.deleteScreenLayout(opt).then(value => {
      commit('setloading',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  duplicateData({dispatch, commit },opt) {
    commit('setloading',true)
    screenLayoutService.duplicateScreenLayout(opt).then(value => {
      commit('setloading',false)
      dispatch('getScreenLayout',{
        search:'',
        itemsPerPage: 10,
        page:1,
        sortBy: ["id"],
      })
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
}
const mutations = {
  resetselecteditem (state) {
    state.itemtotal=0;
    state.selecteditem = {
      items: []
    }
  },
  setItems (state, s) {
    state.selecteditem.items = s
    state.itemtotal=s.length;
  },
  setloading (state, s) {
    state.loading = s
  },
  setdialog (state, s) {
    state.dialog = s
  },
  setselected (state, s) {
    state.selecteditem = s
    state.itemtotal=0;
  },
  setItemTotal (state, s) {
    state.itemtotal = s
  },
  setdataselected (state, s) {
    state.selecteditem = s.data
    state.itemtotal=s.data.items.length
  },
  setdata (state, s) {
    state.screenlayout = []
    state.screenlayout = s.data
    state.totalscreenlayout = s.data.length
    state.loading = false
    state.overlay = false
  }
}

export const ScreenLayoutStore = {
  namespaced: true,
  state,
  actions,
  mutations
}
