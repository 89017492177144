import { handleResponse } from '@/services/handleresponse'
import { apiurl } from '@/services/config'
const API_URL = apiurl

export  const ProductService = {
  getPricePlan,
  getOperator,
  getTerminalH2h,
  getProdukterminalByIdTerminal,
  getProductsByOperatorId
}



function getProdukterminalByIdTerminal (opt) {
  return fetch(API_URL + '/api/admin/v1/produkterminalbyidterminal?idterminal='+ opt.idterminal +'&search='+opt.search, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function getProductsByOperatorId (oprid) {
  if(typeof oprid === 'undefined') return;
  if(oprid==null) return;
  return fetch(API_URL + '/api/admin/v1/productsbyidoperator/'+oprid, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function getPricePlan () {
  return fetch(API_URL + '/api/admin/v1/priceplan', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function getOperator () {
  return fetch(API_URL + '/api/admin/v1/operator', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function getTerminalH2h () {
  return fetch(API_URL + '/api/admin/v1/terminalh2h', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
